/* eslint-disable max-depth */
/* eslint-disable react/jsx-boolean-value */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as R from 'ramda';
import TextField from 'material-ui/TextField';
import {
  detailEdit,
  formStyles,
  styleGuide
} from '@constants/mui-theme';
import DataTypesSelect from '@forms/data-types-select';
import Radio from '@forms/radio';
import Toggle from '@forms/toggle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, RadioGroup } from '@mui';
import FormattedDatePicker from '@shared/formatted-date-picker';
import MomentTimePicker from '@shared/moment-time-picker';
import './forms.scss';

const { RadioButton } = RadioGroup;

const getId = prefix => `${prefix}_picker`;

const FormElement = ({
  data,
  dataSource,
  dataType,
  errors,
  fieldMeta,
  fieldName,
  onChange,
  onError,
  readOnly,
  value
}) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const {
    associatedField,
    componentType,
    customItemFormatter,
    defaultNullLabel,
    isFirstInAssociation,
    minDate,
    maxDate,
    overrideError
  } = fieldMeta;

  useEffect(() => {
    if (moment.isMoment(value)) {
      setDate(moment(value));
      setTime(moment(value));
    }
  }, [value]);

  const handleChange = useCallback(val => {
    if (val === null) {
      if (data[fieldName]) {
        onChange(fieldName, null);
      }
    } else
      if (!data[fieldName] || val !== data[fieldName]) {
        onChange(fieldName, val);
      }
  }, [data, fieldName, onChange]);

  const handleRadioChange = useCallback(event => {
    const val = event.target.value;
    if (val === 'false') {
      if (data[fieldName] !== false) {
        onChange(fieldName, false);
      }
    } else
      if (data[fieldName] !== 'true') {
        onChange(fieldName, true);
      }
  }, [data, fieldName, onChange]);

  const handleDateTimeChange = useCallback(val => {
    if (val === null) {
      handleChange(val);
    } else
      if ((!data[fieldName] || !val.isSame(data[fieldName], 'day,hour,minute')) && val.isValid()) {
        onChange(fieldName, val);
      }
  }, [data, fieldName, handleChange, onChange]);

  const handleError = useCallback(
    (field, error) => {
      if (error === null && typeof errors?.[field] === 'undefined') {
        return;
      }
      if (!errors?.[field]?.includes(error)) {  // Set new error, only if it changed.
        onError(field, error, false);
      }
    },
    [errors, onError]
  );

  const handleValueChange = useCallback(
    (event, val) => {
      handleChange(val);
    },
    [handleChange]
  );

  const handleSelectChange = useCallback(
    (event, index, val) => {
      handleChange(val);
    },
    [handleChange]
  );

  // Validation for start/end fields (they are validated together).
  const validateDate = useCallback(
    val => {
      // Only run this validation if this field has an associate,
      // which means it's a start/end dates combo:
      if (associatedField) {
        // Validate the error if something was entered:
        if (val) {
          // This is the validation, start date must be before
          // the end one:
          if ((minDate && val.isBefore(minDate)) ||
              (maxDate && val.isAfter(maxDate))) {
            const errorMsg = overrideError || `${dataType} must end after it starts`;
            // We only set the error message on the 'end date' field:
            if (isFirstInAssociation) {
              handleError(fieldName, ' ');
              handleError(associatedField, errorMsg);
            } else {
              handleError(fieldName, errorMsg);
              handleError(associatedField, ' ');
            }
          } else {
            handleError(fieldName, null);
            handleError(associatedField, null);
          }
        } else {
          // If the value was cleared, clear the error:
          handleError(fieldName, null);
          handleError(associatedField, null);
        }
      }
    },
    [associatedField, dataType, fieldName, handleError, isFirstInAssociation, maxDate, minDate, overrideError]
  );

  useEffect(() => {
    if (date && time) {
      const newTime = moment(time);
      newTime.year(date.year());
      newTime.month(date.month());
      newTime.date(date.date());
      validateDate(newTime);
      handleDateTimeChange(newTime);
    } else if (date) {
      validateDate(date);
      handleDateTimeChange(date);
    }
  }, [date, handleDateTimeChange, time, validateDate]);

  const handleDateChange = useCallback(
    val => setDate(val ? moment(val) : val),
    [setDate]
  );

  const handleTimeChange = useCallback(
    val => setTime(moment(val)),
    [setTime]
  );

  const onCheck = useCallback(
    event => {  // eslint-disable-line no-unused-vars
      handleChange(!value);
    },
    [handleChange, value]
  );

  const commonProps = useMemo(() => {
    const props = {
      name: fieldName,
      disabled: fieldMeta.read_only || readOnly,
      floatingLabelText: (
        fieldMeta.label
      ) + (
        fieldMeta.required ? ' *' : ''
      ),
      onChange: handleValueChange,
      errorText: Array.isArray(errors?.[fieldName]) ? errors?.[fieldName].join(', ') : null,
      errorStyle: { ...detailEdit.errors },
      floatingLabelStyle: { whiteSpace: 'nowrap' }
    };

    if (errors?.[fieldName]) {
      props.id = `error-${fieldName}`;
    }

    return props;
  }, [
    errors,
    fieldMeta?.label,
    fieldMeta?.read_only,
    fieldMeta?.required,
    fieldName,
    handleValueChange,
    readOnly
  ]);

  const dateProps = useMemo(() => ({
    maxDate: maxDate ? maxDate.toDate() : null,
    minDate: minDate ? minDate.toDate() : null
  }), [maxDate, minDate]);

  const styleName = useMemo(() => fieldMeta?.style || 'col100', [fieldMeta?.style]);

  const fullStyle = useMemo(() => ({ ...detailEdit.columnStyles[styleName] }), [styleName]);

  switch (fieldMeta.type) {
  case 'date':
    return (
      <FormattedDatePicker
        {...commonProps}
        {...dateProps}
        clearable={!fieldMeta.required}
        id={getId(fieldName)}
        onChange={handleDateChange}
        value={date ? date.toDate() : null}
        style={fullStyle}
        styleName={styleName}
        fullWidth
      />
    );
  case 'datetime': {
    let timeFieldText = commonProps.floatingLabelText.replace('date', 'time');
    timeFieldText = timeFieldText.replace('Date', 'Time');
    if (timeFieldText.search('time') < 0 && timeFieldText.search('Time') < 0) {
      timeFieldText = `${timeFieldText} time`;
    }
    const timeFieldCommonProps = {...commonProps, floatingLabelText: timeFieldText};
    switch (styleName) {
    case 'startDatetime':
      return (
        <div style={fullStyle}>
          <FormattedDatePicker
            {...commonProps}
            {...dateProps}
            clearable={!fieldMeta.required}
            id={getId(`${fieldName}_date`)}
            onChange={handleDateChange}
            value={date ? date.toDate() : null}
            style={detailEdit.columnStyles.startDateRangeDate}
          />
          <MomentTimePicker
            {...timeFieldCommonProps}
            onChange={handleTimeChange}
            id={getId(`${fieldName}_time`)}
            value={time || null}
            fullWidth
            style={detailEdit.columnStyles.startDateRangeTime}
          />
        </div>
      );
    case 'endDatetime':
      return (
        <div style={fullStyle}>
          <MomentTimePicker
            {...timeFieldCommonProps}
            onChange={handleTimeChange}
            id={getId(`${fieldName}_time`)}
            value={time || null}
            fullWidth
            style={detailEdit.columnStyles.endDateRangeTime}
          />
          <FormattedDatePicker
            {...commonProps}
            {...dateProps}
            clearable={!fieldMeta.required}
            id={getId(`${fieldName}_date`)}
            onChange={handleDateChange}
            value={date ? date.toDate() : null}
            style={detailEdit.columnStyles.endDateRangeDate}
          />
        </div>
      );
    case 'fullDatetime':
      return (
        <div>
          <FormattedDatePicker
            {...commonProps}
            {...dateProps}
            clearable={!fieldMeta.required}
            id={getId(`${fieldName}_date`)}
            onChange={handleDateChange}
            value={date ? date.toDate() : null}
            fullWidth
            style={fullStyle}
          />
          <MomentTimePicker
            {...timeFieldCommonProps}
            onChange={handleTimeChange}
            id={getId(`${fieldName}_time`)}
            value={time || null}
            fullWidth
            style={fullStyle}
          />
        </div>
      );
    default:
      return (
        <div style={fullStyle} styleName={styleName}>
          <FormattedDatePicker
            {...commonProps}
            {...dateProps}
            clearable={!fieldMeta.required}
            id={getId(fieldName)}
            onChange={handleDateChange}
            value={date ? date.toDate() : null}
            fullWidth
            style={detailEdit.columnStyles.insideCol50Left}
          />
          <MomentTimePicker
            {...timeFieldCommonProps}
            onChange={handleTimeChange}
            value={time || null}
            fullWidth
            style={detailEdit.columnStyles.insideCol50Right}
          />
        </div>
      );
    }
  }
  case 'integer':
    return (
      <TextField
        {...commonProps}
        {...styleGuide.textField}
        type="number"
        value={String(value)}
        maxLength={fieldMeta.max_length}
        inputStyle={{ ...styleGuide.textField.inputStyle, width: '100% - 1px' }}
        style={fullStyle}
        styleName={styleName}
      />
    );
  case 'string':
  case 'email':
    return (
      <TextField
        {...commonProps}
        {...styleGuide.textField}
        value={!value && (fieldMeta.read_only || readOnly) ? '-' : value}
        maxLength={fieldMeta.max_length}
        multiLine
        inputStyle={{ ...styleGuide.textField.inputStyle, margin: '0px' }}
        rowsMax={4}
        style={fullStyle}
        styleName={styleName}
      />
    );
  case 'boolean':
    if (componentType === 'toggle') {
      const errorStyle = {
        ...detailEdit.columnStyles[fieldMeta?.dataCheckErrorStyle]
      };
      return (
        <Toggle
          data={data}
          dataName={fieldMeta?.data || fieldName}
          dataField={fieldMeta?.dataField}
          dataExtract={fieldMeta?.dataExtract}
          dataCheckError={fieldMeta?.dataCheckError}
          dataCheckErrorStyle={errorStyle}
          dataCheckValue={fieldMeta?.dataCheckValue}
          labelPosition="right"
          label={fieldMeta?.label || fieldMeta.label}
          labelStyle={formStyles(fieldMeta.read_only || readOnly).checkbox.labelStyle}
          disabled={fieldMeta.read_only || readOnly}
          name={fieldName}
          onClick={onCheck}
          style={{...fullStyle, ...detailEdit.toggle.style}}
          styleName={styleName}
          toggled={value || false}
        />
      );
    } else if (componentType === 'radio') {
      return (
        <div styleName="radio-container">
          <RadioGroup
            label={commonProps.floatingLabelText}
            name={commonProps.name}
            onChange={handleRadioChange}
            value={value === 'true' || value === true}
          >
            <RadioButton
              disabled={readOnly}
              label={<div>Yes</div>}
              value={true}
            />
            <RadioButton
              disabled={readOnly}
              label={<div>No</div>}
              value={false}
            />
          </RadioGroup>
        </div>
      );
    } else if (componentType === 'dropdown') {
      const booleanDataSource = {
        null: { id: 'null', name: defaultNullLabel || 'TBD', order: 3 },
        true: { id: 'true', name: 'Yes', order: 1 },
        false: { id: 'false', name: 'No', order: 2 }
      };
      return (
        <DataTypesSelect
          {...commonProps}
          dataSource={booleanDataSource}
          onChange={handleSelectChange}
          value={String(value)}
          data={data}
          customItemFormatter={customItemFormatter}
          dataSortField="order"
          dataName={fieldMeta?.data || fieldName}
          multiple={fieldMeta.multiple || false}
          required={fieldMeta.required}
          style={fullStyle}
          styleName={styleName}
        />
      );
    }
    return (
      <div style={{ ...fullStyle, marginTop: '0.5rem' }}>
        <FormControl error={errors?.[fieldName]} component="fieldset">
          <Checkbox
            checked={value || false}
            disabled={fieldMeta.read_only || readOnly}
            label={fieldMeta?.label || fieldMeta.label}
            onChange={onCheck}
            name={fieldName}
            size="small"
          />
          <FormHelperText>{errors?.[fieldName]?.join(',')}</FormHelperText>
        </FormControl>
      </div>
    );
  case 'field':
    if (componentType === 'radio') {
      return (
        <Radio
          dataName={fieldMeta?.data || fieldName}
          disabled={commonProps.disabled}
          label={commonProps.floatingLabelText}
          name={commonProps.name}
          onChange={handleValueChange}
          value={value}
        />
      );
    }
    return (
      <DataTypesSelect
        {...commonProps}
        onChange={handleSelectChange}
        data={data}
        customItemFormatter={customItemFormatter}
        dataSortField={fieldMeta?.dataSortField}
        dataSource={dataSource}
        dataName={fieldMeta?.data || fieldName}
        multiple={fieldMeta.multiple || false}
        required={fieldMeta.required}
        value={value}
        style={fullStyle}
        styleName={styleName}
      />
    );
  case 'nested object': {
    const visibleChildFields = Object.keys(
      R.pickBy(childValue => childValue.style !== 'hidden', fieldMeta.children)
    );
    return (
      <div>
        {visibleChildFields.map(childName => {
          if (childName !== 'id') {
            const childErrors = errors?.[fieldName] ? errors?.[fieldName]?.[childName] || null : null;
            return (
              <FormElement
                key={childName}
                fieldName={`${fieldName}.${childName}`}
                fieldMeta={{
                  ...fieldMeta.children[childName],
                  label: `${fieldMeta.label} ${fieldMeta.children[childName].label.toLowerCase()}`
                }}
                onChange={onChange}
                value={value[childName] || ''}
                readOnly={readOnly}
                errors={childErrors}
              />
            );
          }
          return null;
        })}
      </div>
    );
  }
  default:
    return (
      <TextField
        {...commonProps}
        {...styleGuide.textField}
        {...detailEdit.textField}
        disabled
        value={!value && (fieldMeta.read_only || readOnly) ? '-' : String(value)}
        maxLength={fieldMeta.max_length}
        multiLine
        style={fullStyle}
        styleName={styleName}
      />
    );
  }
};

FormElement.propTypes = {
  data: PropTypes.object,
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataType: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldMeta: PropTypes.object,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

export default memo(FormElement);
