//
// This component renders a date range on a form (i.e. start and end date fields).
//
/* eslint-disable max-depth */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  errorDataField,
  updateDataField
} from '@actions/data-detail-actions';
import { getEntityTypeLabel } from '@constants/config';
import { detailEdit } from '@constants/mui-theme';
import { Checkbox } from '@mui';
import { getMetadata } from '@selectors/forms-selector';
import FormattedDatePicker from '@shared/formatted-date-picker';
import MomentTimePicker from '@shared/moment-time-picker';
import './forms.scss';

const PICKER_PROPS = { floatingLabelStyle: { whiteSpace: 'nowrap' } };

const getTimeLabel = label => {
  const text = label?.replace('date', 'time')?.replace('Date', 'Time');
  if (text?.search('time') < 0 && text?.search('Time') < 0) {
    return `${text} time`;
  }
  return text;
};

const NOT_NULL_ERROR = 'This field may not be null.';

const clearError = (errors, dateField, timeField, dateValue, timeValue, onError) => {
  // Check if the "this field may not be null" error is set for the "date" input box:
  if (errors[dateField]?.includes(NOT_NULL_ERROR)) {
    // If the "date" value is now set, clear the error:
    if (dateValue) {
      onError(dateField, null);
    }
    // If the "time" value is also set, clear the error there too:
    if (timeValue) {
      onError(timeField, null);
    } else {
      // But if the "date" field has an error and the "time" value is not set,
      // manually apply the null error on the time input box.
      //
      // This is needed since the date/time combo is a single field on the backend,
      // to use it on the frontend, we must manually set it.
      onError(timeField, NOT_NULL_ERROR);
    }
  }
};

const DateRange = ({ fieldNames, isPublic, readOnly }) => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { data, error: errors } = useSelector(state => state.dataDetail);
  const metadata = useSelector(state => getMetadata(dataType, isPublic)(state));
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [allDay, setAllDay] = useState(null);

  const dataTypeDisplayName = useMemo(() => getEntityTypeLabel(dataType), [dataType]);

  // Set start/end fields into this component's state once data is loaded:
  useEffect(() => {
    if (data.start_date) {
      setStartDate(moment(data.start_date));
      setStartTime(moment(data.start_date));
    }
    if (data.end_date) {
      setEndDate(moment(data.end_date));
      setEndTime(moment(data.end_date));
    }
    setAllDay(data.all_day);
  }, [data.start_date, data.end_date, data.all_day]);

  const onChange = useCallback((field, value) => {
    if (value === null) {
      if (data[field]) {
        dispatch(updateDataField(field, null));
      }
    } else
      if ((!data[field] || !value.isSame(data[field], 'day,hour,minute')) && value.isValid()) {
        dispatch(updateDataField(field, value));
      }
  }, [data, dispatch]);

  const onBoolChange = useCallback((field, value) => {
    if (value !== data[field]) {
      dispatch(updateDataField(field, value));
    }
  }, [data, dispatch]);

  const onError = useCallback((field, error) => {
    if (error === null && typeof errors[field] === 'undefined') {
      return;
    }
    if (!errors[field]?.includes(error)) {  // Set new error, only if it changed.
      dispatch(errorDataField(field, error, false));
    }
  }, [dispatch, errors]);

  // Clear (or set) start/end date/time errors when they are filled.
  useEffect(() => {
    clearError(errors, 'start_date', 'start_time', startDate, startTime, onError);
    clearError(errors, 'end_date', 'end_time', endDate, endTime, onError);
  }, [endDate, endTime, errors, onError, startDate, startTime]);

  // Convert date/time combo date into a moment() object.
  const getMomentDate = useCallback((date, time) => {
    if ((!fieldNames.includes('all_day') || allDay) && date) {
      return moment(date);
    } else if (fieldNames.includes('all_day') && !allDay && date && time) {
      return moment(date).hours(time.hours())
        .minutes(time.minutes());
    }
    return null;
  }, [allDay, fieldNames]);

  const momentStart = useMemo(() => getMomentDate(startDate, startTime), [getMomentDate, startDate, startTime]);
  const momentEnd = useMemo(() => getMomentDate(endDate, endTime), [getMomentDate, endDate, endTime]);

  // When start/end date/time changes:
  // 1. Validate if the end date is after the start one.
  // 2. Clear errors if fields were emptied.
  // 3. Call the onChange method to set the new values (on the store).
  useEffect(() => {
    if (momentStart && momentEnd) {
      if (momentEnd.isBefore(momentStart)) {
        // We only set the error message on the 'end date' field:
        onError('end_date', `${dataTypeDisplayName} must end after it starts`);
        onError('end_time', ' ');
      } else {
        onError('end_date', null);
        onError('end_time', null);
      }
    } else {
      // If the value was cleared, clear the error:
      onError('start_date', null);
      onError('start_time', null);
      onError('end_date', null);
      onError('end_time', null);
    }
    // Set new values on the store if there are no errors:
    if (!errors.start_date && !errors.start_time) {
      onChange('start_date', momentStart);
    }
    if (!errors.end_date && !errors.end_time) {
      onChange('end_date', momentEnd);
    }
  }, [data, dataTypeDisplayName, errors, momentStart, momentEnd, onError, onChange]);

  const onCheck = useCallback(event => {
    const name = event.target.name;
    const newValue = !data[name];
    onBoolChange(name, newValue);
    setAllDay(newValue);
    if (newValue) {
      setStartTime(null);
      setEndTime(null);
      onError('start_time', null);
      onError('end_time', null);
    }
  }, [data, onBoolChange, onError, setAllDay, setStartTime, setEndTime]);

  const onStartDateChange = useCallback(value => {
    if (value) {
      if (endDate && fieldNames.includes('end_date')) {
        setStartDate(moment(value).set({ hour: '00', minute: '00' }));
      } else {
        setStartDate(moment(value).set({ hour: '00', minute: '00' }));
        setEndDate(moment(value).set({ hour: '00', minute: '00' }));
      }
    } else {
      setStartDate(null);
    }
  }, [endDate, fieldNames, setEndDate, setStartDate]);

  const onEndDateChange = useCallback(value => {
    if (value) {
      setEndDate(moment(value).set({ hour: '00', minute: '00' }));
    } else {
      setEndDate(null);
    }
  }, [setEndDate]);

  const onStartTimeChange = useCallback(value => {
    if (value) {
      setStartTime(value);
    } else {
      setStartTime(null);
    }
  }, [setStartTime]);

  const onEndTimeChange = useCallback(value => {
    if (value) {
      setEndTime(value);
    } else {
      setEndTime(null);
    }
  }, [setEndTime]);

  const timeVisible = useMemo(() => fieldNames.includes('all_day') && !data.all_day, [data.all_day, fieldNames]);
  const startStyle = useMemo(() => timeVisible ? detailEdit.columnStyles.startDateRangeDate : detailEdit.columnStyles.col50, [timeVisible]);
  const endStyle = useMemo(() => timeVisible ? detailEdit.columnStyles.endDateRangeDate : detailEdit.columnStyles.col50, [timeVisible]);

  // Memoize some picker component properties.
  const startDateError = useMemo(() => Array.isArray(errors.start_date) ? errors.start_date.join(', ') : null, [errors.start_date]);
  const startTimeError = useMemo(() => Array.isArray(errors.start_time) ? errors.start_time.join(', ') : null, [errors.start_time]);
  const endDateError = useMemo(() => Array.isArray(errors.end_date) ? errors.end_date.join(', ') : null, [errors.end_date]);
  const endTimeError = useMemo(() => Array.isArray(errors.end_time) ? errors.end_time.join(', ') : null, [errors.end_time]);

  const startDateValue = useMemo(() => startDate ? startDate.toDate() : null, [startDate]);
  const endDateValue = useMemo(() => endDate ? endDate.toDate() : null, [endDate]);

  const startDateLabel = useMemo(
    () => `${metadata.start_date?.label}${metadata.start_date?.required ? ' *' : ''}`,
    [metadata.start_date]
  );
  const startTimeLabel = useMemo(
    () => `${getTimeLabel(metadata.start_date?.label)}${metadata.start_date?.required ? ' *' : ''}`,
    [metadata.start_date]
  );
  const endDateLabel = useMemo(
    () => `${metadata.end_date?.label}${metadata.end_date?.required ? ' *' : ''}`,
    [metadata.end_date]
  );
  const endTimeLabel = useMemo(
    () => `${getTimeLabel(metadata.end_date?.label)}${metadata.end_date?.required ? ' *' : ''}`,
    [metadata.end_date]
  );

  const startDateProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      clearable: !metadata.start_date?.required,
      disabled: metadata.start_date?.read_only || readOnly,
      errorText: startDateError,
      floatingLabelText: startDateLabel,
      id: 'start_date',
      maxDate: fieldNames.includes('end_date') && endDate ? endDate : null,
      name: 'start_date',
      onChange: onStartDateChange,
      value: startDateValue
    }),
    [endDate, fieldNames, metadata.start_date, onStartDateChange, readOnly, startDateError, startDateLabel, startDateValue]
  );

  const endDateProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      clearable: !metadata.start_date?.required,
      disabled: metadata.end_date?.read_only || readOnly,
      errorText: endDateError,
      floatingLabelText: endDateLabel,
      id: 'end_date',
      minDate: startDate || null,
      name: 'end_date',
      onChange: onEndDateChange,
      value: endDateValue
    }),
    [endDateError, endDateLabel, endDateValue, metadata.end_date, metadata.start_date, onEndDateChange, readOnly, startDate]
  );

  const startTimeProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      disabled: metadata.start_date?.read_only || readOnly,
      errorText: startTimeError,
      floatingLabelText: startTimeLabel,
      fullWidth: true,
      id: 'start_time',
      name: 'start_time',
      onChange: onStartTimeChange,
      value: startTime || null
    }),
    [metadata.start_date, onStartTimeChange, readOnly, startTime, startTimeError, startTimeLabel]
  );

  const endTimeProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      disabled: metadata.end_date?.read_only || readOnly,
      errorText: endTimeError,
      floatingLabelText: endTimeLabel,
      fullWidth: true,
      id: 'end_time',
      name: 'end_time',
      onChange: onEndTimeChange,
      value: endTime || null
    }),
    [endTime, endTimeError, endTimeLabel, metadata.end_date, onEndTimeChange, readOnly]
  );

  const checkbox = useMemo(() => (
    <div
      styleName={metadata.all_day?.style || 'col100'}
      style={{ margin: '0.5rem 0 0 2rem', paddingBottom: '0.5rem' }}
    >
      <Checkbox
        checked={data.all_day}
        disabled={readOnly}
        name="all_day"
        label={metadata.all_day?.label}
        onChange={onCheck}
        size="small"
      />
    </div>
  ), [data.all_day, metadata.all_day, onCheck, readOnly]);

  if (Boolean(metadata.all_day?.linebreak)) {
    return (
      <div>
        <FormattedDatePicker {...startDateProps} fullWidth style={{...detailEdit.columnStyles.DateTimeRange}} />
        {timeVisible && <MomentTimePicker {...startTimeProps} style={{...detailEdit.columnStyles.DateTimeRange}} />}
        {fieldNames.includes('end_date') && <FormattedDatePicker {...endDateProps} fullWidth style={{...detailEdit.columnStyles.DateTimeRange}} />}
        {fieldNames.includes('end_date') && timeVisible && <MomentTimePicker {...endTimeProps} style={{...detailEdit.columnStyles.DateTimeRange}} />}
        {fieldNames.includes('all_day') && checkbox}
      </div>
    );
  }
  return (
    <div>
      <FormattedDatePicker {...startDateProps} fullWidth={!timeVisible} style={{...startStyle}} styleName="input-field" />
      {timeVisible && <MomentTimePicker {...startTimeProps} style={{...detailEdit.columnStyles.startDateRangeTime}} styleName="input-field" />}
      {fieldNames.includes('end_date') && timeVisible &&
        <MomentTimePicker
          {...endTimeProps}
          style={{...detailEdit.columnStyles.endDateRangeTime}}
          styleName="input-field"
        />
      }
      {fieldNames.includes('end_date') &&
        <FormattedDatePicker {...endDateProps} fullWidth={!timeVisible} style={{...endStyle}} styleName="input-field" />
      }
      {fieldNames.includes('all_day') && checkbox}
    </div>
  );
};

DateRange.propTypes = {
  fieldNames: PropTypes.array,
  isPublic: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default memo(DateRange);
